import React, { Component, useCallback } from 'react';
import { connectInfiniteHits } from 'react-instantsearch-dom';
import { TypesenseProduct } from '../../models/product';
import { ProductCard } from './product-card';
import Lottie from 'react-lottie';
import noItem from "../../public/lottie/13525-empty.json";
import Link from 'next/link';

type MyProps = { hits: TypesenseProduct[], hasMore: boolean, refineNext: () => void, eventType?: string, handleButtonClick?: (k: string) => void, artist?: string };
type MyState = { timer: number, loadAfterHundered: boolean };

class InfiniteHits extends Component<MyProps, MyState> {
  sentinel = null;
  observer = null;
  myInterval = null;


  state = { loadAfterHundered: false, timer: 0 };

  onSentinelIntersection = entries => {
    const { hasMore, refineNext, hits } = this.props;
    entries.forEach(entry => {
      if (entry.isIntersecting && hasMore && this.state.loadAfterHundered === false) {
        refineNext();
      } else if (hits?.length % 100 === 0 && hits?.length != 0) {
        this.setState({ loadAfterHundered: true });
      }
    });
  };


  componentDidMount() {
    this.myInterval = setInterval(() => {
      const { timer } = this.state

      if (timer < 5) {
        this.setState(({ timer }) => ({
          timer: timer + 1
        }))
      }
    }, 1000)

    this.observer = new IntersectionObserver(this.onSentinelIntersection);
    this.observer.observe(this.sentinel);
  }


  componentWillUnmount() {
    clearInterval(this.myInterval)
    this.observer.disconnect();
  }

  render() {
    const hits = this.props.hits as TypesenseProduct[];
    const artist = this.props.artist;
    return (

      <div className="ais-InfiniteHits products-infiniteHits">
        <ul className="ais-InfiniteHits-list m-0 p-0">

          {hits.filter(h => !!artist ? h.artist === artist : true).map(hit => (
            hit?.status === "listed" &&
            <li key={hit.id} className="ais-InfiniteHits-item">
              <ProductCard hit={hit} />
            </li>
          ))}
          {hits.length === 0 && this.state.timer >= 5 && this.props.eventType != "ArtistCustomizations" &&
            <div className='col-12 '>
              <h1 className='text-center'>{this.props.eventType == 'ArtistStore' ? 'There is no product or service listed for this Artist.' : 'No Item Found!'}</h1>
              {this.props.eventType == 'ArtistStore' ? <div className='text-center'> </div> : <Lottie heigth={0} width={450} options={{ loop: true, autoplay: true, animationData: noItem }} />}
            </div>}

          {hits.length === 0 && this.state.timer >= 5 && this.props.eventType === "ArtistCustomizations" &&
            <div className='col-12 '>
              <h1 className='text-center'>There is no item for this selection, please send a customization request by clicking here!</h1>
              <button className='btn btn-ghost' onClick={() => this.props.handleButtonClick('Commission')}>Send Request</button>
              <Lottie heigth={0} width={450} options={{ loop: true, autoplay: true, animationData: noItem }} />
            </div>}

          {hits.length === 0 && this.state.timer < 5 &&
            <div className='col-12 text-center'>
              <h1>{`There's no art listed for this Artist.`}</h1>
              <p>Have questions? <Link href={`https://flywith.fleato.com/`} passHref className='text-blue'>Read more</Link> </p>
              <Lottie heigth={0} width={450} options={{ loop: true, autoplay: true, animationData: noItem }} />
            </div>}


          <li
            className="ais-InfiniteHits-sentinel"
            ref={c => (this.sentinel = c)}
          />
          {this.state.loadAfterHundered === true ?
            <div className='col-12'>
              <div className='col-2 mx-auto mt-4'>

                <button
                  className="btn-ghost"
                  onClick={() => {
                    this.setState({ loadAfterHundered: false });
                    this.props.refineNext();
                  }}> Load More</button>
              </div>
            </div>
            : null
          }

        </ul>
      </div>
    );
  }
}

export default connectInfiniteHits(InfiniteHits);
