import React, { useEffect, useMemo, useState } from "react";
import genericImage from '../../../public/images/about-artist.png';
import { Wrapper } from "./aboutArtist-style";
import { IoLocationSharp } from "react-icons/io5";
import { useRouter } from "next/router";
import { ArtistProfile } from "../../../models/user";
import ReactPlayer from 'react-player';
import dynamic from "next/dynamic";
import UseWindowSize from "../../../hooks/helper/useWindowSize";
import xss from "xss";

export const AboutArtist = ({ artist, tabClick }: { artist: ArtistProfile, tabClick: any }) => {
  const screenProps = UseWindowSize();
  // const [showCollections, setShowCollections] = useState(false);
  // useEffect(() => {
  //   setShowCollections(true);
  // }, [tabClick]);
  const router = useRouter();

  const [artistName, setArtistName] = useState(router?.query?.artistprofile ?? '');
  const MapComponent = useMemo(() => dynamic(() => import("../../map/map"), { ssr: false }), [artist]);

  useEffect(() => {
    if (router.query?.artistprofile) {
      setArtistName(router.query?.artistprofile)
    }
  }, [router.query?.artistprofile]);


  return (
    <Wrapper>
      <div className="container py-4 py-md-5">
        <div className="row align-items-start py-5">
          <div className="col-md-6 ">
            {artist?.video ?
              <ReactPlayer
                url={artist?.video}
                loop={true}
                controls={true}
                muted={true}
                playing={true}
                height={"100%"}
                width={"100%"}
              /> :
              <img src={artist?.image ?? artist?.avatarImage ?? genericImage.src} alt="Artist-Image" className='img-fluid' />

            }
          </div>
          <div className="col-md-6 pt-3 text-center text-md-start">
            <p className="text-black mb-0">This is</p>
            <h3>{artist?.name}</h3>
            <div dangerouslySetInnerHTML={{
              __html: xss(artist?.description),
            }}>
            </div>
          </div>
        </div>
      </div>
      <div className="py-4 py-md-5 grey-bg grey-borer-top">
        <div className="container py-5">
          <div className="row">
            <div className="col-md-6 pe-md-5">
              {(artist?.locationLatLng && (artist?.locationLatLng?.[0] != 0 || artist?.locationLatLng?.[1] != 0)) ? <MapComponent artists={artist ? [artist] : []} title={artist?.storeName ?? artist?.name} height={screenProps?.windowSize.width >= 720 && screenProps?.windowSize.width < 920 ? "250px" : "450px"} /> : null}
            </div>
            <div className="col-md-6 pt-5 pt-md-3 text-center text-md-start">
              <p className="text-black">Artist Location</p>
              <h3 className="d-flex justify-content-center justify-content-md-start">
                <IoLocationSharp size={24} color="#000" /> {artist?.locationName}
              </h3>
              <p className="mb-0">{artist?.locationDescription}</p>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="container py-4">
        <div className="row align-items-start py-5">
          {setShowCollections && <CollectionsSlider title="Collections" />}
        </div>
      </div> */}
    </Wrapper>
  );
};
