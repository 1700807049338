import React, { useContext, useState } from "react";
import { UserContext } from "../../../contexts/UserContext";
import useCommission from "../../../hooks/useCommission";
import { ArtistProfile } from "../../../models/user";
import { Wrapper } from "./commission-style";
import Lottie from "react-lottie";
import animeLoading from "../../../public/lottie/14117-crypto-loader.json";

export const Commission = ({ artist }: { artist: ArtistProfile }) => {

  const { submitCommissionQuery } = useCommission();
  const { user, openLoginPopup } = useContext(UserContext);
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [firstNameError, setFirstNameError] = useState<boolean>(false);
  const [lastNameError, setLastNameError] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<boolean>(false);
  const [messageError, setMessageError] = useState<boolean>(false);
  const [validEmailError, setvalidEmailError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  const signIn = () => {
    openLoginPopup();
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const storeCommission = async () => {
    if (firstName?.length === 0) {
      setFirstNameError(true);
    }
    if (lastName?.length === 0) {
      setLastNameError(true);
    }
    if (email?.length === 0) {
      setEmailError(true);
    }
    if (validateEmail(email) === null && email?.length > 0) {
      setvalidEmailError(true);
    }
    if (message?.length === 0) {
      setMessageError(true);
    }
    if (firstName?.length > 0 && lastName?.length > 0 && email?.length > 0 && message?.length > 0) {
      await setIsLoading(true);
      await submitCommissionQuery({
        firstName: firstName,
        lastName: lastName,
        email: email,
        message: message,
        userId: user?.uid,
        createdAt: new Date().toISOString().toString(),
        artistId: artist?.id
      });
      await setIsLoading(false);
      alert("Your request has been sent successfully, we will get back to you as soon as possible.");
      setFirstName("");
      setLastName("");
      setEmail("");
      setMessage("");
    }
  }

  return (
    <Wrapper>
      <div className="container pt-5">
        <div className="row justify-content-center">
          <div>
            {artist?.id === "houstonhalos" ? <h3> Don{"'"}t see your car?</h3>
              : <h3>Commissions Available</h3>}
            <ul className="list-unstyled pt-2 pb-2">
              <li className="my-2">
                {/* <h4>H2 Goes Here</h4> */}
                <p>
                  {artist?.id === "houstonhalos" ? "Every car is unique and one of a kind. Please fill out the form with your car make, model and year. We will do our research and add the customization package within 48 hours. Thank you."
                    : "Every piece is unique and one of a kind. Please fill out the custom form if you are interesting in acquiring one. Thank you."}
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="container pb-5">
        <div className="row justify-content-center">
          <div >
            <h3>{`${artist?.displaySettings?.commissionFormTitle ?? 'Request Commission'} `}</h3>
            {user?.uid ?
              <form>
                <div className="my-4 row">
                  <div className="col-sm-6">
                    <input
                      type="text"
                      className="form-control border-grey"
                      placeholder="First Name*"
                      value={firstName}
                      onChange={(e) => {
                        setFirstName(e.target.value);
                        if (e.target.value?.length === 0) {
                          setFirstNameError(true);
                        } else {
                          setFirstNameError(false);
                        }
                      }}
                    />
                    {firstNameError && <p className="text-error">First name is a required field!</p>}
                  </div>
                  <div className="mt-4 mt-md-0 col-sm-6">
                    <input
                      type="text"
                      className="form-control border-grey"
                      placeholder="Last Name*"
                      value={lastName}
                      onChange={(e) => {
                        setLastName(e.target.value);
                        if (e.target.value?.length === 0) {
                          setLastNameError(true);
                        } else {
                          setLastNameError(false);
                        }
                      }}
                    />
                    {lastNameError && <p className="text-error">Last name is a required field!</p>}
                  </div>
                </div>
                <div className="my-4 row">
                  <div className="col-md-12">
                    <input
                      type="text"
                      className="form-control border-grey"
                      placeholder="Email*"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                        if (e.target.value?.length === 0) {
                          setEmailError(true);
                        } else {
                          setEmailError(false);
                        } if (validateEmail(e.target.value) != null) {
                          setvalidEmailError(false);
                        }
                      }}
                    />
                    {emailError && <p className="text-error">Email is a required field!</p>}
                    {validEmailError && <p className="text-error">Please enter a valid email!</p>}
                  </div>
                </div>
                <div className="my-4 row">
                  <div className="col-md-12">
                    <textarea
                      className="form-control border-grey "
                      placeholder="Message*" rows={4}
                      value={message}
                      onChange={(e) => {
                        setMessage(e.target.value);
                        if (e.target.value?.length === 0) {
                          setMessageError(true);
                        } else {
                          setMessageError(false);
                        }
                      }}
                    />
                    {messageError && <p className="text-error">Message is a required field!</p>}
                  </div>
                </div>
                {isLoading ?                   
                  <Lottie height={100} width={100}
                    options={{
                      loop: true,
                      autoplay: true,
                      animationData: animeLoading,
                    }} />
                : <button
                    type="button"
                    className=" btn blue-bg px-3 px-md-4 py-md-2 text-white rounded-9"
                    onClick={() => storeCommission()}
                  >
                    Send
                  </button>
                }
              </form> :
              <button
                type="button"
                className=" btn blue-bg px-3 px-md-4 py-md-2 text-white rounded-9"
                onClick={() => signIn()}
              >
                Sign in to send request
              </button>
            }
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
