import { httpsCallable } from '@firebase/functions';
import { functions } from '../firebaseutil';
import * as Sentry from '@sentry/nextjs'
import { CommissionData } from '../models/agency';

const addCommission = "addCommission"

export default function useCommission() {

    const submitCommissionQuery = async (commissionData: CommissionData) => {
        // console.log({commissionData});
        try {
            const publicProfile = httpsCallable(functions, 'user-userCall');
            await publicProfile({requestType: addCommission, input: {commissionData} });
        } catch(err) {
            Sentry.captureException(err);
            console.log(err);
        }
    }

    return {
        submitCommissionQuery
    }
}
