import styled from 'styled-components';

export const Wrapper = styled.div`
.left-sec {
    .filter{width: 14%;}
  
}
.btn-wrapper{  
      width: 82%;
      @media(min-width:768px){
        width:62%;
      }

     

btn{width:50%;display: flex;align-items: center;
    @media(min-width:920px){
    width:auto;
  }
}
}
.btn-wrapper + div {width:18%;text-align:right}

`