import { useRouter } from 'next/router'
import { useContext, useEffect, useState } from 'react'
import { connectSearchBox } from 'react-instantsearch-dom'
import useDebounce from '../../hooks/helper/useDebounce'
import { FaSearch } from 'react-icons/fa'
import { SearchWrapper } from './searchbar-style'
import { SearchContext } from '../../contexts/typesense/SearchContext'
import { queryWithoutStopWords } from '../../util/util'



/**
 * https://www.algolia.com/doc/api-reference/widgets/search-box/react/
 * https://dev.to/gabe_ragland/debouncing-with-react-hooks-jci
 */

const SearchBar = ({ currentRefinement, isSearchStalled, refine }) => {
  const [searchTerm, setSearchTerm] = useState('')
  const debouncedSearchTerm = useDebounce(searchTerm, 200);
  const router = useRouter();
  const { searchScope } = useContext(SearchContext);


  useEffect(() => {
    if (debouncedSearchTerm) {
      // console.log(searchScope, "$refine - search bar searchTerm = ", searchTerm)
      refine(queryWithoutStopWords(searchTerm));
    } else {
      // console.log(searchScope, "$refine - search bar, clear filter")
      refine('')
    }
  }, [debouncedSearchTerm])

  const startSearching = async () => {
    router.push(`/`);
    document.body.style.overflow = "auto";
  }

  return (

    <SearchWrapper>
      <div className="search-wrapper d-flex mt-0 mt-lg-5">
        <form onSubmit={(e) => { e.preventDefault(); }} noValidate role="search" className="w-100 d-flex ais-SearchBox-form ">
          <button type="button" title="Search for your treasure" className="ais-SearchBox-submit" style={{ paddingBottom: '10px' }}>
            <FaSearch />
          </button>
          <input
            type="search" placeholder="Search items, collections, and accounts" autoComplete="on" className="ais-SearchBox-input py-2"
            onChange={e => { setSearchTerm(e.target.value) }}
          // onKeyPress={event => {
          //   if (event.key === 'Enter' && router.pathname != "/") {
          //     startSearching()
          //   }
          // }}
          />
        </form>
      </div>
    </SearchWrapper>
  )
}

const RecentDropSearchBox = connectSearchBox(SearchBar)

export default RecentDropSearchBox