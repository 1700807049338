import React, { useEffect, useState } from "react";
import { ArtistTabs, ArtistTabs1 } from "../../../components/artist/artist-tabs/artist-tabs";
import { ArtistBanner } from "../../../layout/artistBanner/banner";
import { useRouter } from "next/router";
import { ArtistProfile as Profile } from "../../../models/user";
import Head from "next/head";
import { fleatoConfig } from "../../../fleato-config";
import { tagViewStore } from "../../../lib/events";
import { Agency } from "../../../models/agency";

interface Props {
  artist: Profile,
  agencies?: Agency[];
}
export interface AllNFTs {
  collected: string[];
  id: string;
  minted: string;
  operators: string[];
}

export default function ArtistProfile({ artist, agencies }: Props) {
  const router = useRouter();
  const { id } = router?.query;
  const agencyId = router?.query?.agencyId as string;
  const [tabKey, setTabKey] = useState(artist?.id === "houstonhalos" ? 'ArtistCustomizations' : 'ArtistStore');
  const thisAgency = agencies?.find(item => item?.id === agencyId) ?? agencies?.[0];
  console.log("artist, agencies", artist, agencies);
  // const {allNFTs, isLoading, nftError, collectedNFT, mintedNFT} = useGraphqlApi("0x3fca1e14703b4e7b348e919a3b69a42477b6ba03");

  useEffect(() => {
    tagViewStore(id as string);
    if (!artist?.name) {
      router.push('/');
    }
  }, [id]);

  return (
    <div className="white-bg">
      <Head>
        <title>{artist?.name} at Fleato</title>
        <meta name="description" content={`Meet ${artist?.name ?? id} at Fleato Marketplace`} />
        <link rel="icon" href="/favicon.ico" />
        <meta property="og:title" content={`${artist?.name ?? id} at Fleato`} key="ogtitle" />
        <meta property="og:image" content={artist?.bannerImage ?? artist?.avatarImage ?? "https://www.fleato.com/aboutfleato.png"} key="ogtitle" />
        <meta property="og:url" content={`https://www.fleato.com/m/a/${artist?.id}`} key="ogurl" />
        <meta property="og:site_name" content={"Fleato.com"} key="ogsitename" />
        <meta property="og:description" content={`Meet ${artist?.name ?? id} at Fleato Marketplace`} key="ogdesc" />
      </Head>
      {tabKey == 'Home' && <ArtistBanner artist={artist} tabKey={tabKey} setTabKey={setTabKey} />}
      {tabKey != 'Home' && thisAgency?.preferences?.artistLayout === "layout1" && <ArtistTabs artist={artist} agency={thisAgency} tabKey={tabKey} setTabKey={setTabKey} />}
      {tabKey != 'Home' && thisAgency?.preferences?.artistLayout === "layout2" && <ArtistTabs1 artist={artist} agency={thisAgency} tabKey={tabKey} setTabKey={setTabKey} />}

      {/* <ArtistBanner artist={artist} tabKey={tabKey} setTabKey={setTabKey} />
      <ArtistTabs artist={artist} tabKey={tabKey} setTabKey={setTabKey} /> */}
    </div>
  );
};

const baseUrl = fleatoConfig.websiteBaseUrl;
export async function getStaticPaths() {
  // Return a list of possible value for id
  let artistIds = [];
  try {
    const result = await fetch(`${baseUrl}/api/artists`);
    const artists = await result.json();
    artistIds = (artists as Profile[]).filter(a => !!a.id).map(a => ({ params: { id: a.id } }));
  } catch (e) {
  }
  return { paths: artistIds, fallback: "blocking" };
}

export async function getStaticProps({ params }) {
  // Fetch necessary data for the blog post using params.id
  const { id } = params;
  try {
    const result = await fetch(`${baseUrl}/api/artist/${id}`);
    const artist = await result.json() as Profile;

    const agencies = [];
    if(artist?.memberships?.length > 0){
      for(let membership of artist?.memberships){
        console.log("fetching ", `${baseUrl}/api/agency/${membership?.agencyId}`)
        const resultAgency = await fetch(`${baseUrl}/api/agency/${membership?.agencyId}`);
        const agency = await resultAgency.json() as Agency;  
        agencies?.push(agency);
      }  
    }else {
      console.log("fetching ", `${baseUrl}/api/agency/fleato`)
      const resultAgency = await fetch(`${baseUrl}/api/agency/fleato`);
      const agency = await resultAgency.json() as Agency;  
      agencies?.push(agency);
    }

    return {
      props: {
        artist,
        agencies,
        revalidate: 10,
      },
    };
  } catch (err) {
    return {
      props: {
        artist: null,
        agency: null,
        revalidate: 10,
      },
    }
  }
}
