import React, { useState } from "react";
import { Wrapper } from "./artistStore-style";
import ProductList from "../../../components/product-list/product-list";
import RecentDropSearchBox from "../../searchBar/searchbar";
import { ArtistProfile } from "../../../models/user";
import { Configure } from 'react-instantsearch-dom';
import { SearchProvider } from "../../../contexts/typesense/SearchContext";
import { CustomHierarchicalMenu } from "../../refinement/hierarchical-menu";
import useBranding from "../../../hooks/store/useBranding";
import { fleatoConfig } from "../../../fleato-config";
import { cars, makes } from "../../../data/cars";

export const ArtistStore = ({ artist, eventType, facetFilters: productFilters, showFacets, indexName, handleButtonClick, showSearch }: { artist: ArtistProfile, eventType: string, showFacets?: boolean, indexName?: string, facetFilters: string[], handleButtonClick?: (k: string) => void, showSearch: boolean }) => {
  const { branding, facetFiltersWithBranding } = useBranding();
  const [carMake, setCarMake] = useState<string>();
  const handleSelectCarMake = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e?.target?.value) {
      setCarMake(`categoryHierarchy.lvl1:Automotive > ${e?.target?.value}`);
    }
    else {
      setCarMake("");
    }
  }
  return (
    <Wrapper>
      <SearchProvider searchScope={`${indexName}-${eventType}-artist-store`} indexName={indexName ?? fleatoConfig.indexName}>
        <div className="row align-items-center justify-content-md-center  mb-md-0 ">
          {/* <div className="col-md-7 d-lg-none left-sec d-flex align-items-center pe-md-1">
            <RecentDropSearchBox />

          </div> */}
          <div className=" col-md-12 rt-sec d-flex align-items-center justify-content-md-center justify-content-lg-center flex-md-wrap flex-lg-nowrap  mt-0 mt-md-0">
            {/* <div className="d-none d-md-block col-11 col-md-10 col-lg-5">
              <RecentDropSearchBox />
            </div> */}

            <div className="btn-wrapper d-flex flex-column-reverse flex-md-row align-items-center mx-auto mx-lg-4 mx-md-0 mt-4 mt-lg-0 justify-content-lg-center">
              {artist?.name == "Houston Halos" ? <div className="col-12 col-md-3 mt-3 mt-md-0 mt-lg-5">
                <select className="form-select" onChange={(e) => handleSelectCarMake(e)}>
                  <option value={""}>All</option>
                  {makes()?.map((value) => <option key={`${value}`} value={value} >{value}</option>)}
                </select>
              </div> : null}
              {showSearch &&
                <div className="col-12  col-lg-10 mx-md-3">
                  <RecentDropSearchBox />
                </div>}
              {/* <button
                className="btn white-bg rounded px-lg-4 py-md-2 me-3 mx-md-3  border-grey"
                type="button" style={{ whiteSpace: 'nowrap' }}
              >
                All Items
              </button>
              <button
                className="btn white-bg rounded px-lg-4 py-md-2 border-grey"
                type="button" style={{ whiteSpace: 'nowrap' }}
              >
                Recently Listed
              </button> */}
            </div>

          </div>
        </div>
        <div className="w-100 d-flex justify-content-center overflow-hidden">
          <Configure facetFilters={facetFiltersWithBranding(carMake ? [`artist:${artist?.id ?? ""}`, carMake, ...productFilters] : [`artist:${artist?.id ?? ""}`, ...productFilters])} filters={[`artist:${artist?.id ?? ""}`]} />
          {/* {(!!showFacets || showFacets === undefined) &&
          <div className="col-xl-2" style={{ borderWidth: 1 }}>
            <CustomHierarchicalMenu
              attributes={["categoryHierarchy.lvl0", "categoryHierarchy.lvl1", "categoryHierarchy.lvl2", "categoryHierarchy.lvl3"]}
              showParentLevel={true}
            />
          </div>
          } */}
          <div className="artist-store-tab w-100">
            <ProductList artist={artist?.id} eventType={eventType} handleButtonClick={(k: string) => { handleButtonClick(k); console.log({ k }); }} />
          </div>
        </div>
      </SearchProvider>
    </Wrapper>
  );
};
