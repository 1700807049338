import styled from 'styled-components';

export const Wrapper = styled.div`
.left-sec {
    .filter{width: 14%;}
  
}
.btn-wrapper{    width: 82%;
button{width:50%;display: flex;align-items: center; text-align:center !important; 
}
}
.btn-wrapper + div {width:18%;text-align:right}
.grey-bg{background:#FAFAFA}
.grey-borer-top{border-top:1px solid #EAEAEA}
`