import styled from 'styled-components';

export const SearchWrapper = styled.div`
width:100%;
.search-wrapper{
    button{
        float: left;
        width: 6%;
        padding: 6px 2%;
        border: 1px solid #EAEAEA;
        background:#fff;
        border-right: 0;
        color: rgb(68, 68, 68);
        font-size: 1rem;
       border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        cursor: pointer;
        border-top-right-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
    }
     input{
    
        padding: 6px 10px;
        font-size: 1rem;
        border: 0px;
        float: left;
        width: 94%;
        border: 1px solid #EAEAEA;
        border-left: 0;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        border-top-left-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
        &:focus-visible {
        outline: none;
    }
     } 
     
     @media(max-width:650px){
         clear:both;margin-top:15px;
         float:left;
         width:100%
     }
     @media(max-width:450px){
        button{
    
        width:9%;} 
     }
    }
    `;
export const AuctionSearchWrapper = styled.div`
width:100%;
background:#fff;
border-radius:10px;
border:1px solid #50565F;
overflow:hidden;
padding:0px;

@media(min-width:768px){
    width:50%;
}
.search-wrapper{
    button{
        float: left;
        width: 6%;
        padding: 6px 2%;
        border:none;
        background:#fff;
        border-right: 0;
        color: #50565F;
        font-size: 1.5rem;
       border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        cursor: pointer;
        border-top-right-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
    }
     input{
        font-size: 1.2rem;
        border: 0px;
        float: left;
        height:100%;
        width: 100%;
        // border: 1px solid #EAEAEA;
        border-left: 0;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        border-top-left-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
        &:focus-visible {
        outline: none;
    }
     }
     input::placeholder{
        font-size:16px;
     } 
     
     @media(max-width:650px){
        //  clear:both;
        //  margin-top:15px;
        //  float:left;
        //  width:100%
     }
     @media(max-width:450px){
        button{
    
        width:9%;} 
     }
     @media(min-width:768px){
        button{
        width: 10%;
        }
     }
    }
    `