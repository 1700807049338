import React, { useState, useEffect, useRef } from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { TabsWrapper } from "./artist-tabs-style";
import { ArtistStore } from "../artistStore/artistStore";
import { AboutArtist } from "../aboutArtist/aboutArtist";
import { ArtistProfile } from "../../../models/user";
import UseWindowSize from "../../../hooks/helper/useWindowSize";
import { fleatoConfig } from "../../../fleato-config";
import { DetailWrapper } from "../../../layout/artistBanner/banner-style";
import { shorten } from "../../../util/util";
import xss from "xss";
import striptags from "striptags";
import tick from "../../../public/images/tick.svg";
import Image from 'next/image';
import { FacebookShareButton, TwitterShareButton, EmailShareButton } from "react-share";
import { FacebookIcon, TwitterIcon, EmailIcon } from "react-share";
import { MdLocationPin, MdPhone } from "react-icons/md";
import Link from "next/link";
import { Commission } from "../commission/commission";
import { ModalWrapper } from "../../../styles/checkout-page-style";
import { AiOutlineClose } from "react-icons/ai";
import { BsShare } from "react-icons/bs";
import Modal from 'react-modal';
import { useRouter } from "next/router";
import banner from '../../../public/images/assets/colorful-bg.jpg';
import { Agency } from "../../../models/agency";
interface BannerSize {
  height: number;
  width: number;
}

export const ArtistTabs = ({ artist, tabKey, setTabKey, agency }: { artist: ArtistProfile, tabKey: string, setTabKey: (event: string) => void, agency: Agency }) => {
  const [tabClick, setTabClick] = useState("");
  const handleTabClick = (event: string) => {
    if (typeof window !== "undefined") {
      window.scrollTo({ top: 0, behavior: "smooth" })
    }
    if (event == "AbouttheArtist") {
      window.scrollTo({ top: 400, behavior: "smooth" })
    }
    setTabKey(event)
  }
  useEffect(() => {
    switch (tabKey) {
      case "RequestEstimate":
        window.location.href = "https://services.fleato.com";
        break;
      case "Orders":
        window.location.href = "https://services.fleato.com";
        break;
      case "Schedule":
        window.location.href = "https://services.fleato.com";
        break;
      case "Blog":
        window.location.href = artist?.id === "houstonhalos" ? "https://services.fleato.com" : "https://services.fleato.com";
        break;
      default:
        null;
    }
  }, [tabKey]);

  const screenProps = UseWindowSize();
  const widthOver560 = (screenProps?.windowSize.width ?? 560) - 560;
  const overWidthAt1920 = 1920 - 560;
  const offsetYAt1920 = artist?.displaySettings?.bannerOffsetYAt1920 ?? 0;
  const yOffsetFactor = -1 * (offsetYAt1920 / overWidthAt1920);
  const url = `${fleatoConfig.websiteBaseUrl}/m/a/${artist?.id}`;
  const [btnState, setBtnState] = useState(false);

  const [size, setSize] = useState<BannerSize>();
  const imageRef = useRef(null);
  const [share, setShare] = useState(false);
  const router = useRouter();
  const commissionRef = useRef(null);
  const executeScroll = () => commissionRef?.current?.scrollIntoView();
  return (
    <div className=" col-md-11 position-relative col-lg-8 mx-auto rounded my-4 border overflow-hidden">
      <div>
        <div className="position-relative">
          <div className="position-relative overflowY-hidden">
            {/* <div className="position-relative w-100 h-100"> */}
            <div style={{
              backgroundImage: `url(${banner.src})`, backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              zIndex: 0,
              backgroundColor: '#d2d2d2'
              ,
              width: '100%', height: '100%'
            }} className="mobileBannerBackground">
              <img
                className="d-none"
                style={{ height: "100px" }}
                ref={imageRef}
                alt="this is an demo"
                src={`${fleatoConfig.storageBaseUrl}/artist%2Ftemplate-banner.jpg`}
                onLoad={event => {
                  setSize({
                    height: (event?.target as HTMLImageElement)?.naturalHeight,
                    width: (event?.target as HTMLImageElement)?.naturalWidth
                  });
                }}
              />

            </div>
            <div className="text-center position-relative" style={{ height: 200, zIndex: 3 }} >
            </div>

          </div>
          {artist?.storeName && <div className="mobileBannerTitle" style={{ zIndex: 5 }}>
            {<img src={artist?.logo ?? artist?.avatarImage} style={{ borderRadius: '50%', border: '5px solid #fff' }} height={150} width={150} alt="Logo" />}

          </div>}
        </div>
        <DetailWrapper className="container position-relative mt-4 mt-lg-5 pt-md-5 pt-lg-3 white-bg">
          <div className="row justify-content-between">
            <div className="col-md-8 mb-2 mb-md-0">
              <div className="d-md-flex text-start align-items-center mt-5 mt-md-0">
                {
                  // artist?.logo && <img src={artist?.logo} height={50} className='d-none d-md-block' alt="Logo" />
                }
                <h1 className="d-flex  flex-column justify-content-start mt-4 mt-md-0 mb-2" style={{ textTransform: 'capitalize' }}>

                  {artist?.name ?? artist?.storeName}{" "}
                  <span className="ps-2 d-flex align-items-center justify-content-start ">

                    <span className="pe-1">
                      {artist?.stake >= 10 && <Image src={tick} alt="tick-image" />}
                    </span>{" "}
                    {artist?.stake >= 10 ? "Featured" : ""}
                    <span className="ms-2"> <BsShare className="cursor-pointer" onClick={() => setShare(true)} /></span>

                  </span>
                </h1>

              </div>
              <p className="small-text grey-text w-75" >{artist?.categories?.join(", ")}</p>
              <p dangerouslySetInnerHTML={{
                __html: xss(shorten(striptags(artist?.description != "" ? artist?.description : artist?.shortDescription), 350)),
              }}>
              </p>
              {<a className="bold cursor-pointer" ><span onClick={() => handleTabClick('AbouttheArtist')}> Read More</span></a>}
            </div>
            <div className="col-md-3 col-lg-2">
              <div>

                {(artist?.locationDescription || artist?.locationName) ?
                  <p className="mb-2 mt-2"><MdLocationPin size={20} /> {(artist?.locationDescription && artist?.locationDescription != "") ? artist?.locationDescription : artist?.locationName}</p> : null
                }
              </div>
              {artist?.phoneNumber && <a href={`tel:${artist?.phoneNumber}`}><p className="mb-0 no-wrap"><MdPhone /> {artist?.phoneNumber}</p></a>}
              {artist?.socialHandles?.website && <Link href={artist?.socialHandles?.website} passHref><a><p className="mb-0 no-wrap">{artist?.socialHandles?.website}</p></a></Link>}
              <Modal
                isOpen={share}
                className="share-modal"
                ariaHideApp={false}
              >
                <ModalWrapper>
                  <div style={{ padding: '20px', justifyContent: 'center' }}>
                    <div className="position-absolute close">
                      <AiOutlineClose
                        size={18}
                        color="#000"
                        onClick={() => setShare(false)}
                      />
                    </div>
                    <div className='d-flex flex-column align-items-center'>

                      <ul className="m-0 list-unstyled d-flex">
                        <li className="d-flex flex-column bd-highlight mr-5 px-4 py-3">
                          <FacebookShareButton
                            url={`https://www.fleato.com${router.asPath}`}
                            quote={`Hey, check this out in the Fleato app: `}
                            hashtag={"#fleato"}
                          >
                            <FacebookIcon size={40} round />
                          </FacebookShareButton>
                          <span>Facebook</span>
                        </li>
                        <li className="d-flex flex-column bd-highlight mr-5 px-4 py-3">
                          <TwitterShareButton
                            title={`Hey, check this out in the Fleato app: `}
                            url={`https://www.fleato.com${router.asPath}`}
                            hashtags={["fleato"]}
                          >
                            <TwitterIcon size={40} round />
                          </TwitterShareButton>
                          <span>Twitter</span>

                        </li>
                        <li className="d-flex flex-column bd-highlight px-4 py-3 ">
                          <EmailShareButton
                            body={`Hey, check this out in the Fleato app: `}
                            title={''}
                            url={`https://www.fleato.com${router.asPath}`}
                          >
                            <EmailIcon size={40} round />
                          </EmailShareButton>
                          <span>Email</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </ModalWrapper>
              </Modal>

            </div>
          </div>
        </DetailWrapper>
      </div>
      <TabsWrapper className="container-fluid px-0 mt-4">

        <Tabs defaultActiveKey={tabKey} transition={true} onSelect={(k) => setTabKey(k)} activeKey={tabKey} className=''>
          {artist?.id === "houstonhalos" && <Tab eventKey={'ArtistCustomizations'} title='Halos Packages' >
            <div className="py-4" style={{ width: "96%", alignSelf: "center", marginLeft: "2%", marginRight: "2%" }}><ArtistStore artist={artist} showSearch={false} facetFilters={[`isCustomizable:true`]} eventType='ArtistCustomizations' handleButtonClick={(k) => {
              setTabKey(k); console.log({ k });
            }} /></div>
          </Tab>}
          {artist?.id === "houstonhalos" && <Tab eventKey={'Parts'} title='Parts' >
            <div className="py-4" style={{ width: "96%", alignSelf: "center", marginLeft: "2%", marginRight: "2%" }}>
              <ArtistStore artist={artist} indexName="product_hh" facetFilters={[`isCustomizable:false`]} eventType='Parts' handleButtonClick={(k) => {
                setTabKey(k); console.log({ k });
              }} showSearch={false} /></div>
          </Tab>}
          {artist?.id !== "houstonhalos" &&
            <Tab eventKey={'ArtistStore'} title={artist?.displaySettings?.artistStoreTabName ?? 'Artist Store'} >
              <div className="py-4" style={{ width: "96%", alignSelf: "center", marginLeft: "2%", marginRight: "2%" }}>
                <ArtistStore artist={artist} facetFilters={[]} eventType="ArtistStore" showSearch={false} />
              </div>
            </Tab>
          }
          {artist?.id !== "houstonhalos" &&
            <Tab eventKey={'Commission'} title={artist?.displaySettings?.commissionTabName ?? 'Commission'}>
              <div className="col-md-10 col-lg-8 mx-auto" ref={commissionRef}>
                <Commission artist={artist} />
              </div>
            </Tab>
          }
          {artist?.id == "houstonhalos" &&
            <Tab eventKey={'Orders'} title='Orders' >
              <></>
            </Tab>
          }
          {artist?.id == "houstonhalos" &&
            <Tab eventKey={'Schedule'} title='Schedule' >
              <></>
            </Tab>
          }
          {artist?.id == "houstonhalos" &&
            <Tab eventKey={'RequestEstimate'} title='Request Estimate' >
              <></>
            </Tab>
          }
          <Tab eventKey={'AbouttheArtist'} title='About Us'>
            <AboutArtist tabClick={tabClick} artist={artist} />
          </Tab>
          {/* <Tab eventKey={'Blog'} title='Blog' >
          <></>
        </Tab> */}
        </Tabs>
      </TabsWrapper>
    </div>
  );
};

export const ArtistTabs1 = ({ artist, tabKey, setTabKey, agency }: { artist: ArtistProfile, tabKey: string, setTabKey: (event: string) => void, agency: Agency }) => {
  const [tabClick, setTabClick] = useState("");
  const handleTabClick = (event: string) => {
    if (typeof window !== "undefined") {
      window.scrollTo({ top: 0, behavior: "smooth" })
    }
    if (event == "AbouttheArtist") {
      window.scrollTo({ top: 400, behavior: "smooth" })
    }
    setTabKey(event)
  }
  useEffect(() => {
    switch (tabKey) {
      case "RequestEstimate":
        window.location.href = "https://services.fleato.com";
        break;
      case "Orders":
        window.location.href = "https://services.fleato.com";
        break;
      case "Schedule":
        window.location.href = "https://services.fleato.com";
        break;
      case "Blog":
        window.location.href = artist?.id === "houstonhalos" ? "https://services.fleato.com" : "https://services.fleato.com";
        break;
      default:
        null;
    }
  }, [tabKey]);

  const screenProps = UseWindowSize();
  const widthOver560 = (screenProps?.windowSize.width ?? 560) - 560;
  const overWidthAt1920 = 1920 - 560;
  const offsetYAt1920 = artist?.displaySettings?.bannerOffsetYAt1920 ?? 0;
  const yOffsetFactor = -1 * (offsetYAt1920 / overWidthAt1920);
  const url = `${fleatoConfig.websiteBaseUrl}/m/a/${artist?.id}`;
  const [btnState, setBtnState] = useState(false);

  const [size, setSize] = useState<BannerSize>();
  const imageRef = useRef(null);
  const [share, setShare] = useState(false);
  const router = useRouter();
  const commissionRef = useRef(null);
  const executeScroll = () => commissionRef?.current?.scrollIntoView();
  return (
    <div className=" col-md-11 position-relative col-lg-8 mx-auto rounded my-4 border overflow-hidden">
      <div>
        <div className="position-relative">
          <div className="position-relative overflowY-hidden">
            {/* <div className="position-relative w-100 h-100"> */}
            <div style={{
              backgroundImage: `url(${banner.src})`, backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              zIndex: 0,
              backgroundColor: '#d2d2d2'
              ,
              width: '100%', height: '100%'
            }} className="mobileBannerBackground">
              <img
                className="d-none"
                style={{ height: "100px" }}
                ref={imageRef}
                alt="this is an demo"
                src={`${fleatoConfig.storageBaseUrl}/artist%2Ftemplate-banner.jpg`}
                onLoad={event => {
                  setSize({
                    height: (event?.target as HTMLImageElement)?.naturalHeight,
                    width: (event?.target as HTMLImageElement)?.naturalWidth
                  });
                }}
              />

            </div>
            <div className="text-center position-relative" style={{ height: 200, zIndex: 3 }} >
            </div>

          </div>
          {artist?.storeName && <div className="mobileBannerTitle" style={{ zIndex: 5 }}>
            {<img src={artist?.logo ?? artist?.avatarImage} style={{ borderRadius: '50%', border: '5px solid #fff' }} height={150} width={150} alt="Logo" />}

          </div>}
        </div>
        <DetailWrapper className="container position-relative mt-4 mt-lg-5 pt-md-5 pt-lg-3 white-bg">
          <div className="row justify-content-between">
            <div className="col-md-8 mb-2 mb-md-0">
              <div className="d-md-flex text-start align-items-center mt-5 mt-md-0">
                {
                  // artist?.logo && <img src={artist?.logo} height={50} className='d-none d-md-block' alt="Logo" />
                }
                <h1 className="d-flex  flex-column justify-content-start mt-4 mt-md-0 mb-2" style={{ textTransform: 'capitalize' }}>

                  {artist?.name ?? artist?.storeName}{" "}
                  <span className="ps-2 d-flex align-items-center justify-content-start ">

                    <span className="pe-1">
                      {artist?.stake >= 10 && <Image src={tick} alt="tick-image" />}
                    </span>{" "}
                    {artist?.stake >= 10 ? "Featured" : ""}
                    <span className="ms-2"> <BsShare className="cursor-pointer" onClick={() => setShare(true)} /></span>

                  </span>
                </h1>

              </div>
              <p className="small-text grey-text w-75" >{artist?.categories?.join(", ")}</p>
              <p dangerouslySetInnerHTML={{
                __html: xss(shorten(striptags(artist?.description != "" ? artist?.description : artist?.shortDescription), 350)),
              }}>
              </p>
              {<a className="bold cursor-pointer" ><span onClick={() => handleTabClick('AbouttheArtist')}> Read More</span></a>}
            </div>
            <div className="col-md-3 col-lg-2">
              {agency?.preferences?.contactMessage && <><p className="mb-0 no-wrap"><MdPhone /> {agency?.preferences?.contactMessage}</p></>}
              {artist?.socialHandles?.website && <Link href={artist?.socialHandles?.website} passHref><a><p className="mb-0 no-wrap">{artist?.socialHandles?.website}</p></a></Link>}
              <Modal
                isOpen={share}
                className="share-modal"
                ariaHideApp={false}
              >
                <ModalWrapper>
                  <div style={{ padding: '20px', justifyContent: 'center' }}>
                    <div className="position-absolute close">
                      <AiOutlineClose
                        size={18}
                        color="#000"
                        onClick={() => setShare(false)}
                      />
                    </div>
                    <div className='d-flex flex-column align-items-center'>

                      <ul className="m-0 list-unstyled d-flex">
                        <li className="d-flex flex-column bd-highlight mr-5 px-4 py-3">
                          <FacebookShareButton
                            url={`https://www.fleato.com${router.asPath}`}
                            quote={`Hey, check this out in the Fleato app: `}
                            hashtag={"#fleato"}
                          >
                            <FacebookIcon size={40} round />
                          </FacebookShareButton>
                          <span>Facebook</span>
                        </li>
                        <li className="d-flex flex-column bd-highlight mr-5 px-4 py-3">
                          <TwitterShareButton
                            title={`Hey, check this out in the Fleato app: `}
                            url={`https://www.fleato.com${router.asPath}`}
                            hashtags={["fleato"]}
                          >
                            <TwitterIcon size={40} round />
                          </TwitterShareButton>
                          <span>Twitter</span>

                        </li>
                        <li className="d-flex flex-column bd-highlight px-4 py-3 ">
                          <EmailShareButton
                            body={`Hey, check this out in the Fleato app: `}
                            title={''}
                            url={`https://www.fleato.com${router.asPath}`}
                          >
                            <EmailIcon size={40} round />
                          </EmailShareButton>
                          <span>Email</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </ModalWrapper>
              </Modal>

            </div>
          </div>
        </DetailWrapper>
      </div>
      <TabsWrapper className="container-fluid px-0 mt-4">

        <Tabs defaultActiveKey={tabKey} transition={true} onSelect={(k) => setTabKey(k)} activeKey={tabKey} className=''>
          {artist?.id === "houstonhalos" && <Tab eventKey={'ArtistCustomizations'} title='Halos Packages' >
            <div className="py-4" style={{ width: "96%", alignSelf: "center", marginLeft: "2%", marginRight: "2%" }}><ArtistStore artist={artist} showSearch={false} facetFilters={[`isCustomizable:true`]} eventType='ArtistCustomizations' handleButtonClick={(k) => {
              setTabKey(k); console.log({ k });
            }} /></div>
          </Tab>}
          {artist?.id === "houstonhalos" && <Tab eventKey={'Parts'} title='Parts' >
            <div className="py-4" style={{ width: "96%", alignSelf: "center", marginLeft: "2%", marginRight: "2%" }}>
              <ArtistStore artist={artist} indexName="product_hh" facetFilters={[`isCustomizable:false`]} eventType='Parts' handleButtonClick={(k) => {
                setTabKey(k); console.log({ k });
              }} showSearch={false} /></div>
          </Tab>}
          {artist?.id !== "houstonhalos" &&
            <Tab eventKey={'ArtistStore'} title={artist?.displaySettings?.artistStoreTabName ?? 'Artist Store'} >
              <div className="py-4" style={{ width: "96%", alignSelf: "center", marginLeft: "2%", marginRight: "2%" }}>
                <ArtistStore artist={artist} facetFilters={[]} eventType="ArtistStore" showSearch={false} />
              </div>
            </Tab>
          }
          {artist?.id !== "houstonhalos" &&
            <Tab eventKey={'Commission'} title={artist?.displaySettings?.commissionTabName ?? 'Commission'}>
              <div className="col-md-10 col-lg-8 mx-auto" ref={commissionRef}>
                <Commission artist={artist} />
              </div>
            </Tab>
          }
          {artist?.id == "houstonhalos" &&
            <Tab eventKey={'Orders'} title='Orders' >
              <></>
            </Tab>
          }
          {artist?.id == "houstonhalos" &&
            <Tab eventKey={'Schedule'} title='Schedule' >
              <></>
            </Tab>
          }
          {artist?.id == "houstonhalos" &&
            <Tab eventKey={'RequestEstimate'} title='Request Estimate' >
              <></>
            </Tab>
          }
          <Tab eventKey={'AbouttheArtist'} title='About Us'>
            <AboutArtist tabClick={tabClick} artist={artist} />
          </Tab>
          {/* <Tab eventKey={'Blog'} title='Blog' >
          <></>
        </Tab> */}
        </Tabs>
      </TabsWrapper>
    </div>
  );
};
