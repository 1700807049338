import React, { useState, useContext, useEffect, useMemo } from "react";
import { ArtistBannerWrapper, BannerWrapper, DetailWrapper } from "./banner-style";
import tick from "../../public/images/tick.svg";
import Image from "next/image";
import { useRouter } from "next/router";
import { CategoriesContext } from "../../contexts/CategoriesContext";
import { shorten } from "../../util/util";
import { FaShareSquare } from "react-icons/fa";
import Popup from 'reactjs-popup';
import { FacebookShareButton, TwitterShareButton, EmailShareButton } from "react-share";
import { FacebookIcon, TwitterIcon, EmailIcon } from "react-share";
import dynamic from "next/dynamic";
import 'reactjs-popup/dist/index.css';
import { fleatoConfig } from "../../fleato-config";
import xss from "xss";
import UseWindowSize from "../../hooks/helper/useWindowSize";
import { ArtistProfile } from "../../models/user";
import striptags from "striptags";
import Map from "../../components/google-map/google-map";
import aboutBanner from '../../public/images/banner/about-banner.jpg';
import Carousel from 'react-bootstrap/Carousel';
import { Commission } from "../../components/artist/commission/commission";


export const ArtistBanner = ({ artist, tabKey, setTabKey }: { artist: ArtistProfile, tabKey: string, setTabKey: (event: string) => void }) => {
  const router = useRouter();
  const { currentArtist } = useContext(CategoriesContext);
  const [isReadMore, setIsReadMore] = useState(false);
  const MapComponent = useMemo(() => dynamic(() => import("../../components/map/map-thumbnail"), { ssr: false }), []);
  const [btnState, setBtnState] = useState(false);
  // console.log("artist banner", artist);
  const screenProps = UseWindowSize();
  const url = `${fleatoConfig.websiteBaseUrl}/m/a/${artist?.id}`
  const widthOver560 = (screenProps?.windowSize.width ?? 560) - 560;
  const overWidthAt1920 = 1920 - 560;
  const offsetYAt1920 = artist?.displaySettings?.bannerOffsetYAt1920 ?? 0;
  const yOffsetFactor = -1 * (offsetYAt1920 / overWidthAt1920);
  const handleTabClick = (event: string) => {
    if (typeof window !== "undefined") {
      window.scrollTo({ top: 0, behavior: "smooth" })
    }
    setTabKey(event)
  }
  return (
    <>
      {/* <div className="position-relative " >
        <div style={{
          backgroundImage: `url(${artist?.bioImage ?? `${fleatoConfig.storageBaseUrl}/artist%2Ftemplate-banner.jpg`})`, backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover', backgroundPositionY: yOffsetFactor * widthOver560
        }} className="mobileBannerBackground"></div>
        <div className="text-center position-relative" style={{ height: 300, zIndex: 3 }} >
        </div>
        {artist?.storeName && screenProps?.windowSize.width <= 920 && <div className="mobileBannerTitle" style={{ zIndex: 5 }}>
          <h1>{artist?.storeName}</h1>
        </div>}
      </div>
      <DetailWrapper className="container text-center text-md-start position-relative mt-md-5 pt-md-5 pt-lg-3 white-bg">
        <div className="row ">
          <div className="col-md-6 col-lg-5 text-center text-md-start mb-4 mb-md-0">
            <div className="d-md-flex align-items-center mt-5 mt-md-0">
              {artist?.logo && <img src={artist?.logo} height={30} alt="Logo" />}
              <h1 className="d-flex flex-column justify-content-center justify-content-md-start mt-4 mt-md-0" style={{ textTransform: 'capitalize' }}>

                {artist?.name ?? artist?.storeName}{" "}
                <span className="ps-2 d-flex align-items-center justify-content-center justify-content-md-start mt-1">
                  <span className="pe-1">
                    <Image src={tick} alt="tick-image" />
                  </span>{" "}
                  Featured


                  <Popup trigger={<button className="btn">Share <FaShareSquare size={20} className="me-1" /></button>}
                    position="top center" modal><div>
                      <h3>Share </h3>
                      <ul className="m-0 list-unstyled d-flex">
                        <li className="d-flex flex-column bd-highlight mr-5 px-4 py-3">
                          <FacebookShareButton
                            url={url}
                            quote={`Hey, check this out ${shorten(artist!.name, 60)} in the Fleato app: ${url}`}
                            hashtag={"#fleato"}
                          >
                            <FacebookIcon size={40} round />
                          </FacebookShareButton>
                          <span>Facebook</span>
                        </li>
                        <li className="d-flex flex-column bd-highlight mr-5 px-4 py-3">
                          <TwitterShareButton
                            title={`Hey, check this out ${shorten(artist!.name, 60)} in the Fleato app: ${url}`}
                            url={url}
                            hashtags={["fleato"]}
                          >
                            <TwitterIcon size={40} round />
                          </TwitterShareButton>
                          <span>Twitter</span>

                        </li>
                        <li className="d-flex flex-column bd-highlight px-4 py-3 ">
                          <EmailShareButton
                            body={`Hey, check this out ${shorten(artist!.name, 60)} in the Fleato app: ${url}`}
                            title={url}
                            url={url}
                          >
                            <EmailIcon size={40} round />
                          </EmailShareButton>
                          <span>Email</span>
                        </li>
                      </ul>

                      <div><input type="text" value={url} disabled size={30} style={{ height: '40px' }} /> <span className={btnState ? 'btn btn-light' : 'btn btn-primary'} onClick={() => {
                        navigator.clipboard.writeText(url);
                        setBtnState(true);
                      }}>Copy</span></div>
                    </div>
                  </Popup>
                </span>
              </h1>
            </div>
            {isReadMore ? <p dangerouslySetInnerHTML={{
              __html: xss(artist?.description),
            }}>
            </p> :
              <p dangerouslySetInnerHTML={{
                __html: xss(shorten(striptags(artist?.description), 350)),
              }}>
              </p>}
            {artist?.description?.length > 349 && <a className="bold cursor-pointer"><span onClick={() => setIsReadMore(!isReadMore)}> {isReadMore ? `Read Less` : `Read More`}</span></a>}
          </div>
          <div className=" col-md-6 offset-lg-2 col-lg-5 text-center text-md-start mb-4 mb-md-0">
            <div className="row">
              <div className="col-6 col-sm-6 col-md-6 col-lg-3">
                <h4>Categories</h4>
                <ul className="list-unstyled list">

                  {artist?.categories?.map((value) => {
                    return <li key={`${value}`}>{value}</li>
                  })}
                </ul>
              </div>
              <div className="col-6 col-sm-6 col-md-6 col-lg-3">
                <h4>Geography</h4>
                <ul className="list-unstyled list">
                  <li>{artist?.locationName}</li>
                </ul>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-6">
                {artist?.locationLatLng &&
                  // <MapComponent height={"200px"} artists={artist ? [artist] : []} />
                  <div>
                    <Map
                      height={150}
                      location={{
                        lat: artist?.locationLatLng?.[0],
                        lng: artist?.locationLatLng?.[1],

                      }}
                      zoomLevel={1}
                    />
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </DetailWrapper> */}
      <ArtistBannerWrapper>
        {/* <div className="artist-carousal " style={{ overflow: 'hidden' }}>
          <Carousel>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={artist?.bioImage ?? `${fleatoConfig.storageBaseUrl}/artist%2Ftemplate-banner.jpg`}
                alt="art-banner"
              />
              <Carousel.Caption className='text-align-left'>
                <h3 className="title">{artist?.name}</h3>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </div> */}
        <div className="about-banner overflow-hidden position-relative" >
          <img
            className="d-block w-100 h-100 position-absolute"
            src={artist?.bioImage ?? `${fleatoConfig.storageBaseUrl}/artist%2Ftemplate-banner.jpg`}
            alt="art-banner"
            style={{ zIndex: 0, objectFit: 'cover', objectPosition: screenProps?.windowSize?.width <= 768 ? '0 0' : '0 -100px ' }}
          />
          <div className="d-flex justify-content-around h-100">
            <div className='d-none d-md-block col-4 h-100'>
            </div>
            <div className="col-10 col-md-6 col-lg-4 position-relative glassmorphic mt-md-3 me-3" style={{ zIndex: 2 }}>
              <div className="glassmorphic-content pb-3">
                <h2 className="about-artist-title mb-3">{artist?.name}</h2>
                <p className="artist-description mb-3" style={{ maxHeight: screenProps?.windowSize?.width >= 768 ? 545 : 300, overflowY: 'scroll' }} dangerouslySetInnerHTML={{
                  __html: xss(artist?.description != "" ? artist?.description : artist?.shortDescription),
                }} />
                {/* <button className="btn white-ghost-btn" onClick={() => handleTabClick('AbouttheArtist')}>Know More</button> */}
              </div>
            </div>
          </div>
        </div>
        <div className="artist-store ">
          <div className="grid-view position-absolute bg-primary">
            <div>

            </div>

          </div>
          <div className="align-center">
            <h2 className="artist-store-title mb-3">Store</h2>
            <button className="btn white-btn" onClick={() => handleTabClick('ArtistStore')}>Shop Now </button>
          </div>
        </div>
        <div className="d-md-flex justify-content-md-between justify-content-lg-center mt-5 mx-2 mx-md-5">
          <div className="col-md-5 col-lg-6 position-relative">
            <div className="vertical-md-center">
              <p className="mb-0 fs-30" >Want to <strong>commission Custom Art Work?</strong>
                <br />
                Fill this form and I will reach back.</p>
            </div>
          </div>
          <div className="col-md-6">
            <Commission artist={artist} />
          </div>
        </div>
      </ArtistBannerWrapper>
    </>
  );
};
