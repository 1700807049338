import styled from 'styled-components';

export const BannerWrapper = styled.div`


p{margin-bottom:5px;}
  display: flex;
  align-items: center;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position:0 -300px;
  height:400px;
  position:relative;
    z-index:1;
    @media(max-width:600px){
      height:300px;
      background-position:0 -100px;
    }
    @media(min-width:600px){
      height:300px;
      background-position:0 -300px;
    }
    @media(min-width:920px){
      background-position:0 -300px;
    height:400px;
    }

.bannerTitle{
  position:absolute;
  
  h1{
    color:#fff;
    text-transform:uppercase;
    background-color:#000;
    padding:0 10px;
  }
  @media(max-width:600px){
    bottom:0;
    left:25%;
    transform:translate(4%);
  }
  @media(min-width:600px){
    bottom:50px;
  }
  @media(min-width:920px){
    bottom:0;

  }

}
.profile{
  position:absolute;
  left:0;
  right:0;
  text-align:center;

  @media(min-width:600px){
    bottom:-40px;
  }
  @media(min-width:920px){
  bottom:-123px;

  }
}

  @media (max-width: 1199px) {
   
  }

  @media (max-width: 991px) {
  
  }

  @media (max-width: 768px) {
  
  }
  @media (width: 768px) {
   

  }
  @media (max-width: 600px) {
   

    h1 {
      font-size: 1.5rem;
    }
    .btn {
      padding: 0.3rem 0.5rem !important;
      font-size: 0.9rem;
    }
  }
`;
export const ArtistBannerWrapper = styled.div`
.artist-carousal {
  .carousel {
    overflow: hidden;

  }

  .carousel-inner::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(10, 10, 10, 0.4);
  }

  .title {
    font-size: 30px;
    color: #fff;
  }

  .carousel-caption {
    top: 50%;
    left: 5%;
    text-align: left
  }

  .carousel-indicators {
    display: none;
  }

  .carousel-caption,
  .carousel-control-prev,
  .carousel-control-next {
    z-index: 10;
  }

  .carousel-control-prev,
  .carousel-control-next {
    top: 30%;
    bottom: auto;
  }

  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    height: 4rem;
    width: 4rem;
  }

  @media screen and (min-width:768px) {
    .title {
      font-size: 60px;
      color: #fff;
    }

    .carousel-caption {
      top: 60%;
      left: 10%;
    }

    .carousel-control-prev,
    .carousel-control-next {
      top: 40%;
      bottom: auto;
    }

  }

  @media screen and (min-width:920px) {
    .carousel-caption {
      top: 40%;
      left: 10%;
      right: none;
    }

    .carousel-control-prev,
    .carousel-control-next {
      top: 30%;
      bottom: auto;
    }

    height: 100vh;

  }

  @media screen and (min-width:1280px) {

    .carousel-control-prev,
    .carousel-control-next {
      top: 25%;
      bottom: auto;
    }
  }
}

.about-banner {
  height: 50vh;
  overflow: hidden;


  .artist-description {
    color: #F6F6F6;
    font-size: 20px;
    text-transform: uppercase;
    line-height: 30px;
    // letter-spacing: 0.5px;
    // word-spacing: 5px;
    text-align:initial;
  }

  .white-ghost-btn {
    font-size: 20px;
    font-weight: 600;
    text-transform: capitalize;
    color: #FFFFFF;
    border: 3px solid #FFFFFF;
    border-radius: 2px 2px 2px 2px;
    padding: 10px 20px 10px 20px;
  }

  .glassmorphic {
    background-color: #03264875;
    text-align: center;
  }

  .glassmorphic-content {
    margin-top: 10%;
  }



}


.about-artist-title,
.artist-store-title {
  font-size: 25px;
  color: #fff;
}



.artist-store {
  height: 25vh;
  position: relative;

  .artist-store-title {
    z-index: 2;
    position: relative;
  }

  .align-center {
    padding-top: 18%;
    text-align: center;
  }

  .white-btn {
    font-size: 20px;
    font-weight: 600;
    text-transform: capitalize;
    fill: #000000;
    color: #000000;
    background-color: #FFFFFF;
    border-radius: 1px 1px 1px 1px;
    padding: 10px 20px 10px 20px;
    z-index: 2;
    position: relative;
  }

  .grid-view {
    width: 100%;
    height: 100%;
    background-image: url('https://imgv3.fotor.com/images/videoImage/Fotor-GoArt-ai-art-generator-banner.png');
    background-repeat: no-repeat;
    background-size: cover;
  }
}

@media screen and (min-width:768px) {

  .glassmorphic-content {
    margin-top: 25%;
  }

  .about-artist-title,
  .artist-store-title {
    font-size: 45px !important;
    color: #fff;
  }

  .artist-store,
  .about-banner {
    height: 50vh;
  }
}

@media screen and (min-width:920px) {

  .artist-store,
  .about-banner {
    height: 100vh;
  }
}

.artist-store::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(10, 10, 10, 0.4);
}

`;
export const DetailWrapper = styled.div`
.gap-10{
  gap:10px;
}
.no-wrap{
  white-space:nowrap;
}
width:96%;
margin:0 auto;
max-width: 100%;
    h1 span{font-size:.9rem}
    .list li{
      // font-weight:700;
    }
    .list li:hover{color:#5A5A5A}
.profileName{
  width: 50vw;
}
.description{
  width: 50vw;
}
.marginTop-10 {
  margin-top: 10vh;
}
.marginTop-5 {
  margin-top:5vh;
}
`
export const ProfileWrapper = styled.div`
width: 99%;
// margin-top: -10vh;
z-index:1;
position: relatiuve;
.page-header {
  height: 380px;
  background-position:center;
}
.avatar-top{
  position:"relative";
  margin-top: -5vh;
  @media (min-width: 600px && max-width: 1199px) {
  }
  @media (max-width: 400px) {
    margin-top: -10vh
  }
}

.header-filter:after, .header-filter:before {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    display: block;
    left: 0;
    top: 0;
    content: "";
}
.main-raised {
  margin: -60px 30px 0;
  border-radius: 6px;
  box-shadow: 0 16px 24px 2px rgba(0,0,0,.14), 0 6px 30px 5px rgba(0,0,0,.12), 0 8px 10px -5px rgba(0,0,0,.2);
}
.main {
  background: #FFF;
  position: relative;
  z-index: 3;
}

`

export const ScrollWrapper = styled.div`
.featuredEdit {
  margin-left: 85%;
  margin-top: -15%;
  z-index:3;
}
`
