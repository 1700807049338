import styled from 'styled-components';

export const TabsWrapper = styled.div`
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
    border-color: transparent

}
.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    color: #000000;
    background-color: #fff;
    border: 1px solid transparent;
    border-bottom: 4px solid #000;
}
.nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    align-items: center;
    justify-content: center;
}
@media (min-width:768px){
.float-md-left{
    float: left;
}
}
.nav-tabs .nav-link {
  
    padding: 0;
    margin: 0 10px;
    padding-bottom: 12px;
}
.nav-link{color:#5A5A5A;font-weight:bold}
`